import jwtDecode from "jwt-decode";

let initialState = (token => { 
  token = token ? JSON.parse(token) : null;
  return {
    isAuthenticating: false,
    errorMessage: null,
    token: token ? token.access_token : null
  };
})(localStorage.authToken);

const userMetaData = (userdata => {
  userdata = userdata ? JSON.parse(userdata) : null;
  return {
  id: userdata ? userdata.id : null,
  currentUser: userdata ? userdata.id : null,
  type: userdata ? userdata.type : null,
  nombre: userdata ? userdata.nombre : null,
  email: userdata ? userdata.email : null
}
})(localStorage.userMetaData);

initialState = {...initialState, ...userMetaData}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {
        ...state,
        isAuthenticating: true
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        isAuthenticating: false,
        errorMessage: action.errorMessage
      };
    case "LOGIN_SUCCESS":
      return {
        isAuthenticating: false,
        currentUser: action.user.id,
        errorMessage: null,
        type: action.user.type,
        id: action.user.id,
        name: action.user.name,
        email: action.user.id,
        token: action.token.access_token
      };
    case "LOGOUT":
      localStorage.removeItem("authToken");
      return {
        isAuthenticating: false,
        currentUser: null,
        errorMessage: null,
        type: null,
        name: null,
        token: null
      };
    default:
      return state;
  }
};
export default reducer;
